/* eslint-disable import/prefer-default-export */
import { OpenMethod } from 'components/RegwallDialog';
import { GLOBAL_REGWALL_TYPES } from 'containers/GlobalRegwall/constants';
import { routes } from 'utils/routes';

export const TagType = {
    LINK: 'link',
    A: 'a',
};

const wizardSearchParams = new URLSearchParams(`t=${JSON.stringify({ wizardTrigger: 'Nav Bar' })}`);

const wizardQueryString = `?${wizardSearchParams.toString()}`;

function createSearchSittersWithRegwall() {
    const params = new URLSearchParams();

    params.set('regwall', GLOBAL_REGWALL_TYPES.OWNER);
    params.set('next', routes.search.profiles());
    params.set('openMethod', OpenMethod.FIND_A_SITTER_NAVBAR_LINK);
    params.set('isRegwallCloseable', false);
    params.set('heading', 'Create your free account');
    params.set(
        'subheading',
        'Create your free account to view verified and reviewed pet sitters near you'
    );

    return {
        i18nKey: 'navigation_searchSitters',
        url: `${routes.search.profiles()}#${params.toString()}`,
        tag: TagType.LINK,
    };
}

function createSearchSitsWithRegwall() {
    const params = new URLSearchParams();

    params.set('regwall', GLOBAL_REGWALL_TYPES.SITTER);
    params.set('next', routes.search.listings());
    params.set('openMethod', OpenMethod.FIND_A_SIT_NAVBAR_LINK);
    params.set('isRegwallCloseable', false);
    params.set('heading', 'Create your free account');
    params.set(
        'subheading',
        'Create your free account to view verified and reviewed house sits near you'
    );

    return {
        i18nKey: 'navigation_searchSits',
        url: `${routes.search.listings()}#${params.toString()}`,
        tag: TagType.LINK,
    };
}

const MenuItems = {
    home: {
        i18nKey: 'navigation_home',
        url: '/',
        tag: TagType.LINK,
    },
    variation1SitterHomepage: {
        i18nKey: 'navigation_home',
        url: routes.search.listings(),
        tag: TagType.LINK,
    },
    blog: {
        i18nKey: 'navigation_blog',
        url: '/blog/',
        tag: TagType.LINK,
    },
    joinNow: {
        i18nKey: 'navigation_joinNow',
        url: routes.accounts.explorePlans(),
        tag: TagType.LINK,
    },
    joinNowVariation: {
        i18nKey: 'navigation_joinNow_variation',
        url: routes.accounts.explorePlans(),
        tag: TagType.LINK,
    },
    joinNowVariationShort: {
        i18nKey: 'navigation_joinNow_variation_short',
        url: routes.accounts.explorePlans(),
        tag: TagType.LINK,
    },
    partialOwnerStartFreeTrial: {
        i18nKey: 'components_cta_start_free_trial',
        url: routes.accounts.explorePlans(),
        tag: TagType.LINK,
    },
    checkoutShort: {
        i18nKey: 'navigation_account_checkoutShort',
        url: routes.accounts.checkout.payment(),
        tag: TagType.LINK,
    },
    checkout: {
        i18nKey: 'navigation_account_checkout',
        url: routes.accounts.checkout.payment(),
        tag: TagType.LINK,
    },
    referAFriend: {
        i18nKey: 'navigation_account_referAFriend',
        url: routes.accounts.referAFriend(),
        tag: TagType.LINK,
    },
    referFriend: {
        i18nKey: 'navigation_account_referFriend',
        url: routes.accounts.referAFriend(),
        tag: TagType.LINK,
    },
    searchSits: {
        i18nKey: 'navigation_searchSits',
        url: routes.search.listings(),
        tag: TagType.LINK,
    },
    searchSitsWithRegwall: createSearchSitsWithRegwall(),
    getSitter: {
        i18nKey: 'navigation_getASitter',
        url: routes.search.profiles(),
        tag: TagType.LINK,
    },
    findHouses: {
        i18nKey: 'navigation_findHouses',
        url: routes.search.listings(),
        tag: TagType.LINK,
    },
    findAHouseSitWizard: {
        i18nKey: 'navigation_searchSits',
        url: `${routes.search.findAHouseSitWizard()}${wizardQueryString}`,
        tag: TagType.LINK,
        trackLink: 'Find a House Sit Nav bar link',
    },
    searchSitters: {
        i18nKey: 'navigation_searchSitters',
        url: routes.search.profiles(),
        tag: TagType.LINK,
    },
    searchSittersHomepageCTA: {
        i18nKey: 'pages_home_hero_homepage_cta_test_search_sitters',
        url: routes.search.profiles(),
        tag: TagType.LINK,
    },
    searchSittersWithRegwall: createSearchSittersWithRegwall(),
    meetSitters: {
        i18nKey: 'navigation_meetSitters',
        url: routes.search.profiles(),
        tag: TagType.LINK,
    },
    findASitterWizard: {
        i18nKey: 'navigation_searchSitters',
        url: `${routes.search.findASitterWizard()}${wizardQueryString}`,
        tag: TagType.LINK,
        trackLink: 'Find a Pet Sitter nav bar link',
    },
    findASitterWizardHomepageCTA: {
        i18nKey: 'pages_home_hero_homepage_cta_test_search_sitters',
        url: routes.join.petParents(),
        tag: TagType.LINK,
        trackLink: 'Find pet care nav bar link',
    },
    howItWorks: {
        i18nKey: 'navigation_howItWorks',
        url: routes.howItWorks.findASitter(),
        tag: TagType.LINK,
    },
    login: {
        i18nKey: 'navigation_login',
        url: routes.login(),
        tag: TagType.LINK,
    },
    help: {
        i18nKey: 'navigation_help',
        url: 'https://support.trustedhousesitters.com/',
        tag: TagType.A,
    },
    settings: {
        i18nKey: 'navigation_account_settings',
        url: routes.user.settings.hub(),
        tag: TagType.LINK,
    },
    dashboard: {
        i18nKey: 'navigation_account_dashboard',
        url: routes.accounts.profile.dashboard(),
        tag: TagType.LINK,
    },
    inbox: {
        i18nKey: 'navigation_account_inbox',
        url: routes.user.inbox(),
        tag: TagType.LINK,
    },
    oldFavorites: {
        i18nKey: 'navigation_account_favorites',
        url: routes.accounts.profile.favorites(),
        tag: TagType.LINK,
    },
    favorites: {
        i18nKey: 'navigation_account_favorites',
        url: routes.user.favourites(),
        tag: TagType.LINK,
    },
    verifications: {
        i18nKey: 'navigation_account_verifications',
        url: routes.user.verifications(),
        tag: TagType.LINK,
    },
    pastSits: {
        i18nKey: 'navigation_account_past_sits',
        url: routes.user.assignments.pastSits(),
        tag: TagType.LINK,
    },
    pastSitters: {
        i18nKey: 'navigation_account_past_sitters',
        url: routes.user.assignments.pastSitters(),
        tag: TagType.LINK,
    },
    upgrade: {
        i18nKey: 'navigation_account_upgradeFullMembership',
        url: routes.accounts.explorePlans(),
        tag: TagType.LINK,
    },
    upgradeCTA: {
        i18nKey: 'navigation_upgrade_cta',
        url: routes.accounts.explorePlans(),
        tag: TagType.LINK,
    },
    upgradePage: {
        i18nKey: 'navigation_upgrade_cta',
        url: routes.user.upgrade(),
        tag: TagType.LINK,
    },
    renew: {
        i18nKey: 'navigation_account_renew',
        url: routes.accounts.explorePlans(),
        tag: TagType.LINK,
    },
    aboutUs: {
        i18nKey: 'navigation_aboutUs',
        url: '/pages/about/',
        tag: TagType.LINK,
    },
    forum: {
        i18nKey: 'navigation_forum',
        url: 'https://forum.trustedhousesitters.com/',
        tag: TagType.A,
    },
    findASitter: {
        i18nKey: 'navigation_findASitter',
        url: '/house-and-pet-sitters/',
        tag: TagType.LINK,
    },
    trustAndSafety: {
        i18nKey: 'navigation_trustAndSafety',
        url: '/trust-and-safety/',
        tag: TagType.LINK,
    },
    pricing: {
        i18nKey: 'navigation_pricing',
        url: routes.accounts.signUp.pricing(),
        tag: TagType.LINK,
    },
    privacy: {
        i18nKey: 'navigation_privacy',
        url: '/privacy-policy/',
        tag: TagType.LINK,
    },
    siteMap: {
        i18nKey: 'navigation_siteMap',
        url: '/sitemap/',
        tag: TagType.LINK,
    },
    careers: {
        i18nKey: 'navigation_careers',
        url: '/careers/',
        tag: TagType.LINK,
    },
    explorePlans: {
        i18nKey: 'navigation_explore_plans',
        url: '/accounts/explore-plans/',
        tag: TagType.LINK,
    },
    pricingPlans: {
        i18nKey: 'navigation_pricing',
        url: '/pricing/',
        tag: TagType.LINK,
    },
    yourListing: {
        i18nKey: 'navigation_your_listing',
        url: routes.user.listing.edit.hubRedirect(),
        tag: TagType.LINK,
    },
    yourProfile: {
        i18nKey: 'navigation_your_profile',
        url: routes.user.profile.edit.hub(),
        tag: TagType.LINK,
    },
};

const HowItWorksTabItems = {
    findASitter: {
        i18nKey: 'navigation_howItWorks_findASitter',
        url: routes.howItWorks.findASitter(),
        tag: TagType.LINK,
    },
    findAHouseSit: {
        i18nKey: 'navigation_howItWorks_findAHouseSit',
        url: routes.howItWorks.findAHouseSit(),
        tag: TagType.LINK,
    },
};

export { HowItWorksTabItems, MenuItems };
